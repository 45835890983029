
import { API } from '../utils/api';
export const Global = {
    namespace: 'global',
    state: {
        activePath: 'home',
        visible:true,
        userInfo:{},
        newWebsite:false
    },
    reducers: { 
        updateUserInfo(state,action){
			const userInfo = action.payload
			return{
				...state,
				userInfo
			}
        },
        updateType(state,action){
            const newWebsite = action.payload
            return{
				...state,
				newWebsite
			}
        },
        updateSidebar(state, action) {
            return {
                ...state,
                ...action.payload
            }
        }
    },
    effects: {
        *fetchUserInfo(action, { select, call, put }){
			const info = yield call(API.getUserInfo, {});
			if(info.code === 2000){
                yield put({ type: 'updateUserInfo', payload: info.data });
            }
        },
    },
    subscriptions: {
        routeWatch({ dispatch, history }) {
            return history.listen(({ pathname }) => {
                document.documentElement.scrollTop=0;
                let otherPaths = ['/login','/registor','/forget']
                
                dispatch({ type: 'updateSidebar', payload: {
                    visible:otherPaths.indexOf(pathname)==-1,
                    activePath:pathname
                } });
            });
        },
    },
};
