import * as Tools  from 'jad-tool'

export function isDotCom(){
    let host:string = location.host
    return host.split('.').pop()== 'com';
}

export  function isShowLogin(){
    return process.env.REACT_APP_ENV =='prod-bj' &&(location.host =="www.hzjizhun.com" || location.host =="www.hzjizhun.cn") || 
    process.env.REACT_APP_ENV !='prod-bj';
}

export function isLogin(){
    return  !!Tools.getCookie('HToken') && ( location.hash.indexOf('/login') ==-1 ||  location.hash.indexOf('/registor') ==-1 ) 
}