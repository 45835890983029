import React, { Component } from "react";

import { PageComponent, PageLayout, PageTitle, PageContent } from 'jad-lib-react';

export class NotFound extends Component {
  render() {
    return (
      <span>404</span>
    );
  }
}
